<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <el-button 
          @click="handleSubmit('formData')" 
          type="primary" 
          class="el-button--mini" 
          :disabled="alertTlt == '编辑仓库'">
          <i class="el-icon-plus"></i>新增仓库
        </el-button>
        <el-button 
          size="mini" 
          type="primary" 
          :loading="submitBtnLoad" 
          @click="handleSubmit('formData')" 
          :disabled="alertTlt != '编辑仓库'">保存
        </el-button>
      </div>
      <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="140px" class="demo-ruleForm" :inline="true">
          <el-form-item label="仓库名称：" prop="name">
                <el-input
                v-model="formData.name"
                placeholder="请输入仓库名称"
                style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="负责人：" prop="principal">
                <el-input
                v-model="formData.principal"
                placeholder="请输入仓库负责人"
                style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="phone">
                <el-input
                v-model="formData.phone"
                placeholder="请输入仓库电话"
                style="width: 300px"
                ></el-input>
            </el-form-item>
            <el-form-item label="所属地区：" prop="adcode">
                <area-cascader
                style="width: 300px"
                :areaValue="formData.adcode"
                @areaChange="areaEvent"
                ></area-cascader>
            </el-form-item>
            <el-form-item label="地址：" prop="address">
                <el-input
                v-model="formData.address"
                placeholder="请输入仓库地址"
                style="width: 300px"
                ></el-input>
            </el-form-item>
            <!-- <el-form-item label="经纬度：" prop="location">
                <el-input v-model="formData.location" placeholder="请输入经纬度" style="width: 300px"></el-input>
            </el-form-item> -->
            <el-form-item label="备注：" prop="remark">
                <el-input
                v-model="formData.remark"
                placeholder="请输入备注"
                style="width: 300px"
                type="textarea"
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
import areaCascader from "../components/areaCascader.vue";
export default {
  components: {
    areaCascader,
  },
  data() {
    return {
      radio: "1",
      editNumData: {
        type: "1",
      },
      submitBtnLoad: false,
      index: "1",
      addShow: false,
      transferShow: false,
      transferGoodsList: [],
      tableData: [],
      alertTlt: "",
      loading: true,
      goodsPagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //form表单数据
      formData: {
        name: "",
        principal: "",
        phone: "",
        address: "",
        remark: "",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      detailedButton: "", //查看流水权限
      goodsRelButton: "", //查看商品明细权限
      splitButton: "", //商品拆分权限
      warehouseGoodsButton: "", //修改商品库存价格权限
      edit_numButton: "", //修改库存
      assmbledButton: "", //商品组合
      transferButton: "", //移库
      addPrivilege: {
        name: [
          {
            required: true,
            message: "请输入仓库名称",
            trigger: "blur",
          },
        ],
        adcode: [
          {
            required: true,
            message: "请选择所属区域",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入仓库地址",
            trigger: "blur",
          },
        ],
      },
      editNumShow: false, //修改库存
      warehouseId: "", //仓库id
      warehouseName: "", //仓库名称
    };
  },

  created() {
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "warehouse_update") {
        this.updateButton = "1";
      } else if (item.authority == "warehouse_query") {
        this.searchButton = "1";
      } else if (item.authority == "warehouse_add") {
        this.addButton = "1";
      } else if (item.authority == "warehouse_delete") {
        this.delButton = "1";
      }
    });
  },
   mounted(){
    let that = this;
    let data = this.$route.params ? this.$route.params : this.$route.query;
    console.log(data)
    if (data.type == 'edit'){
      this.alertTlt = "编辑仓库";
      // 获取详情
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.formData = data;
          }
        });
    }else{
      this.alertTlt = '新增仓库'
    }
    
  },
  methods: {
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
    },

    //点击添加权限按钮事件
    handleAdd() {
      this.addShow = true;
      this.alertTlt = "新增仓库";
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //点击编辑按钮操作
    handleEdit(row) {
      this.formData = row;
      this.addShow = true;
      this.alertTlt = "编辑仓库";
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.alertTlt == "新增仓库") {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/warehouse/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.formData = {};
                _this.$router.back();
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/warehouse/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.formData = {};
                _this.$router.back();
              } else {
                _this.submitBtnLoad = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    // 区域选择
    areaEvent(e) {
      this.$set(this.formData, "adcode", e);
    },
  },
};
</script>